import Layout from "./common/layout"
import Head from "./common/Head"
import ThankYou from "pages/service/Livestream/ThankYou"

const ThankYouPage = () => {
  return (
    <>
      <Head title={"Thank you"} description={"Thank you"} showCaptcha={false} />
      <Layout displayApplyBtn={false}>
        <ThankYou />
      </Layout>
    </>
  )
}

export default ThankYouPage
